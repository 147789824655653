export const Palavras = ['sagaz',
'negro',
'mexer',
'exito',
'algoz',
'termo',
'senso',
'nobre',
'plena',
'afeto',
'mutua',
'sutil',
'inato',
'vigor',
'secao',
'etica',
'sanar',
'fazer',
'cerne',
'ideia',
'tenue',
'anexo',
'moral',
'poder',
'assim',
'fosse',
'justo',
'futil',
'honra',
'muito',
'razao',
'mutuo',
'icone',
'habil',
'gozar',
'posse',
'haver',
'digno',
'avido',
'genro',
'ansia',
'causa',
'dizer',
'sobre',
'entao',
'ceder',
'casal',
'denso',
'dever',
'apice',
'censo',
'comum',
'dengo',
'saber',
'etnia',
'culto',
'tempo',
'fugaz',
'vicio',
'sonho',
'pifio',
'temor',
'amigo',
'nenem',
'mundo',
'regra',
'forte',
'pudor',
'louco',
'impio',
'desse',
'impor',
'manso',
'pauta',
'criar',
'bocal',
'ainda',
'estar',
'fluir',
'jeito',
'ontem',
'graca',
'pedir',
'serio',
'atras',
'viril',
'pleno',
'sabio',
'servo',
'ordem',
'temer',
'falar',
'banal',
'gerar',
'vendo',
'juizo',
'forma',
'obvio',
'obter',
'dogma',
'enfim',
'saude',
'havia',
'matiz',
'meiga',
'coisa',
'reter',
'presa',
'crise',
'alibi',
'praxe',
'favor',
'grato',
'tedio',
'feliz',
'acaso',
'orgia',
'ameno',
'vital',
'magoa',
'obito',
'adiar',
'citar',
'fator',
'sinto',
'tomar',
'heroi',
'certo',
'farsa',
'valor',
'visao',
'exato',
'mesmo',
'homem',
'gesto',
'achar',
'amplo',
'uniao',
'facil',
'imune',
'olhar',
'atuar',
'falta',
'dubio',
'levar',
'ritmo',
'otica',
'ativo',
'gente',
'debil',
'nocao',
'fardo',
'arduo',
'sesta',
'cesta',
'fusao',
'doido',
'falso',
'ranco',
'casta',
'leito',
'passo',
'horda',
'igual',
'cacar',
'unico',
'epico',
'ciume',
'vacuo',
'dorso',
'tendo',
'sabia',
'terno',
'nicho',
'burro',
'viver',
'varao',
'ambos',
'ideal',
'apoio',
'morar',
'ouvir',
'senao',
'rigor',
'forca',
'xeque',
'leigo',
'brega',
'pobre',
'capaz',
'claro',
'algum',
'velho',
'ponto',
'morte',
'cruel',
'calma',
'inata',
'botar',
'genio',
'jovem',
'prece',
'cauda',
'arido',
'outro',
'vulto',
'fonte',
'todos',
'rever',
'flora',
'vasto',
'breve',
'setor',
'tanto',
'sonso',
'preso',
'ancia',
'sonsa',
'vazio',
'ardor',
'fauna',
'prado',
'avaro',
'ficar',
'tenra',
'abrir',
'salvo',
'lazer',
'humor',
'pouco',
'noite',
'houve',
'vetor',
'coesa',
'aureo',
'acima',
'atomo',
'segue',
'chulo',
'puxar',
'motim',
'fitar',
'serie',
'entre',
'saida',
'adeus',
'parar',
'credo',
'morro',
'exijo',
'brisa',
'bando',
'soldo',
'impar',
'vadia',
'pegar',
'solto',
'gerir',
'manha',
'opcao',
'minha',
'virus',
'linda',
'trama',
'junto',
'raiva',
'fugir',
'leite',
'cocar',
'sumir',
'otimo',
'lider',
'terra',
'aviao',
'arcar',
'plano',
'epoca',
'serao',
'porra',
'fixar',
'treta',
'prazo',
'praga',
'copia',
'nunca',
'retem',
'exame',
'livro',
'peixe',
'ajuda',
'fenda',
'verba',
'chata',
'idolo']